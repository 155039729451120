@import "mixin";
@import "animate";

$offcanvas-width: 260px !default;
$color-base: #684F43;
$color-base50: rgba(104,79,67,.5);
$color-base20: rgba(104,79,67,.2);
$color-orange: #F4974C;
$color-red: #c33;
$color-green: #006a32;

@charset "UTF-8";
@include keyframes(animationOpacity){
	0% {
		opacity:0;
	}
	30% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
body{
	@include animation(animationOpacity 1.2s ease 0.15s);
	font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
		// font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif;
	color: $color-base;
  a{
    color: $color-base;
  }
  a:hover{
    color: $color-base;
  }
  a.disable {
    pointer-events: none;
    cursor: default;
  }
}
.gnav{
	height: 60px;
	background: #fff;
	position: fixed;
	z-index: 1000;
	width: 100vw;
	box-shadow: 1px 0 10px $color-base50;
	@include mq('min', 'lg') {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.menu-trigger,.overlay{
			display: none !important;
		}
		.logo{
			width: 200px;
			padding-left:15px;
			img{
				width: 180px;
			}
		}
		ul{
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			li{
				font-size: .95rem;
				a{
					padding: 10px 6px 5px;
					display: inline-block;
					&:hover{
						text-decoration: none;
						opacity: .8;
					}
				}
			}
		}
		.mirai-mark{
			width: 170px;
			padding:0 20px 0 0;
			text-align: right;
			img{
				width: 135px;
			}
		}
	}
	@include mq('min', 'xl') {
		ul{
			li{
				a{
					padding: 10px 15px 5px;
				}
			}
		}
	}
	@include mq('max', 'lg') {
		.menu-trigger {
			display: inline-block;
			width: 24px;
			height: 19px;
			vertical-align: middle;
			cursor: pointer;
			position: fixed;
			top: 20px;
			left: 20px;
			z-index: 1002;
			transform: translateX(0);
			span {
				display: inline-block;
				box-sizing: border-box;
				position: absolute;
				left: 0;
				width: 100%;
				height: 3px;
				background-color: $color-base;
				transition: all .5s;
				&:nth-of-type(1) {
					top: 0;
				}
				&:nth-of-type(2) {
					top: 8px;
				}
				&:nth-of-type(3) {
					bottom: 0;
				}
			}
			&.active {
				transform: translateX(0);
				z-index: 2000;
				span {
					background-color: $color-base !important;
					&:nth-of-type(1) {
						transform: translateY(8px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translateY(-8px) rotate(45deg);
					}
				}
			}
		}
		.logo{
			width: 180px;
			top:10px;
			left:0;
			right:0;
			margin: 0 auto;
			position: absolute;
			img{
				width: 180px;
			}
		}
		ul {
	  	width: 100vw;
	    height: 100%;
	    position: fixed;
	    left: 0;
			z-index: 1005;
	    transform: translate(-100vw);
	    transition: all .5s;
	    overflow-x:hidden !important;
			opacity: 0;
	    margin: 0 0 5px;
			padding-top: 70px;
			background: #fff url('/_assets/img/nav-bg.png') no-repeat bottom center;

	    &.open {
	      transform: translateX(0);
	      overflow:hidden;
	      box-sizing:border-box;
				opacity: 1;
		    li {
					opacity: 1;
					text-align: center;
				}
	    }
			li {
				color: $color-base;
				opacity: 0;
				padding: .5rem;
	      &:hover{
	        // background: rgba(225,225,225,.3);
	      }
	      a {
	        color: $color-base;
	        display: inline-block;
	        text-decoration: none;
		      padding: 8px;
					&:hover{
						// color: #fff;
		      }
	      }
				&.lang{
					display: flex;
					a+a{
			      padding-left: 10px;
						margin-left: 5px;
					}
				}
	    }
		}
		.mirai-mark{
			display: none;
		}
	  .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background-color: #fff;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1001;
	    opacity: 0;
	    transition: opacity .5s;
	    top: 70px;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
	    }
	  }
	}
}

footer{
  font-size: .8rem;
  text-align: center;
  margin: 3rem 0 1rem;
	a:hover{
		text-decoration: none;
	}
  #chiikimiraikenin{
		color: #333;
		  font-size: .75rem;
		img{
			display: block;
			margin: 5px auto 10px;
		}
  }
}
.to-page-top {
	@include mq('min', 'xs') {
	  z-index: 1100;
	  position: fixed;
		bottom: 20px;
		right: 20px;
		width: 50px;
		height: 50px;
		text-align: center;
		background: #fff;
		background: rgba(255,255,255,0.9);
		border-radius: 40px;
		box-shadow:0 1px 2px rgba(0,0,0,0.3);
	  display: none;
	  a {
	    text-decoration: none;
	    display: block;
	    text-align: center;
			color: $color-base;
			padding: 10px 0 15px;
			font-size: .6rem;
		  background: url("/_assets/img/btn-totop.png") no-repeat bottom center;
		  background-size: 35%;
	  }
	}
	@include mq('max', 'xs') {
		display: none !important;
	}
}

#localnav ul{
  padding-left: 0;
  margin-bottom: 0;
  li{
    font-size: .9rem;
    list-style: none;
    a{
      display: block;
      text-align: center;
      border:1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}


.slick-dots{
	bottom:-20px;
	li{
		height: 10px;
		width: 10px;
		margin:0 3px;
		button{
			height: 10px;
			width: 10px;
			padding: 0;
			&::before{
				width: 10px;
				height: 10px;
				line-height: 10px;
			}
		}
	}
}

#contents{
	padding-top: 80px;
	// font-family: 'TsukuARdGothic-Regular','ヒラギノ丸ゴ ProN','Hiragino Maru Gothic ProN','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',YuGothic,'Yu Gothic',sans-serif;
  #headline{
		margin-bottom: 2rem;
		#breadcrumb{
			font-size: .75rem;
		}
    h1{
      font-size: 2rem;
			font-weight: bold;
      vertical-align: middle;
			line-height: 1.6;
			text-align: center;
			padding: 20px 0;
			@include mq('min', 'lg'){
				font-size: 3rem
			}
			a{
				text-decoration: none;
			}
    }
		.tab{
			text-align: center;
			margin: 2rem 0 3rem;
			font-size: .8rem;
			span{
				padding:.3rem .1rem;
				margin:.5rem;
				cursor: pointer;

			}
			.current{
				border-bottom: 1px solid $color-base;
			}
		}
  }
  @include mq('max', 'lg') {
		#headline{
			margin-bottom: 1rem;
		}
    #breadcrumb{
      padding-bottom:20px;
    }
  }
  h2{
    font-size: 1.5rem;
		line-height: 1.6;
		font-weight: bold;
		@include mq('max', 'sm') {
		  font-size: 1.4rem;
		}
		@include mq('max', 'xs') {
		  font-size: 1.2rem;
		}
		&.lead{
			font-feature-settings : "palt";
		}
  }
  h3{
    line-height: 1.5;
		font-weight: bold;
		font-size: 1.375rem;
  }
  p{
    line-height: 1.8;
  }
	dt, dd, th, td{
    line-height: 1.6;
  }
	dt{
		@include mq('min', 'md') {
			font-size: 1.1rem;
		}
	}
	dd{
		margin-bottom: .4rem;
	}
  li{
    font-size: .9rem;
    line-height: 1.8;
  }
	a{
		text-decoration: underline;
	}
  .color-red{
    color:$color-orange;
  }
  .btn a{
    background: #eee;
		display: block;
		text-decoration: none;
    &:hover, &.hover{
      background: $color-base;
      color: #fff;
			text-decoration: none;
    }
  }
  .viewmore{
		@include mq('max', 'md') {
			text-align: center;
		}
		a.btn{
			background: $color-base;
			color: #fff;
			font-weight: bold;
			padding:.8rem;
			text-decoration: none;
	    &:hover, &.hover{
		    background: #ccc;
				color: $color-base;
				text-decoration: none;
	    }
			@include mq('max', 'md') {
				display: block;
			}
	  }
	}
	.table{
		color: $color-base;
	}
	img{
		max-width: 100% !important;
	}
	#event-cal{
		table{
			caption{
				caption-side: top;
				text-align: center;
				height: 45px;
				color: $color-base
			}
			th, td{
				text-align: center;
				border: 0;
				padding:5px;

			}
			td{
				color: rgba(104,79,67,.25);
				&.today{
					// opacity: 1;
					background: rgba(244, 156, 85, .4);
				}
				&.on{
					color: $color-base;
					a{
						display: block;
					}
					&:hover{
						background: rgba(244, 156, 85, .4);
					}
				}
				a{
					text-decoration: none;
				}
			}
		}
	}
	#event-list{
		ul{
			padding-top: 5px;
			li{
				&+li{
					border-top: 1px solid #ccc;
				}
				&:hover{
					background: rgba(244, 156, 85, .4);
				}
				a{
					text-decoration: none;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					padding: 8px 10px;
					span{
						display: block;
						width: 78%;
						&.date{
							width: 20%;
						}
					}
				}
			}
		}
	}
}

.home{
	// background: url('/_uploads/home-bg.jpg') no-repeat center / cover;

	#feature{
		padding-top: 60px;
		.slick-next{
			right: 5%;
			width: 40px;
			height: 40px;
			text-align: center;
			@media screen and ( min-width : 684px ) and ( max-width : 1024px ){
				right: 17%;
			}
			@media screen and ( min-width : 1024px ){
				right: 28%;
			}
			&::before{
			  position: absolute;
			  bottom: 0;
				right:5px;
			  content: '\f054';
			  color: #fff;
			  font-family: fontAwesome;
				opacity: 1;
			}
		}
		.slick-prev{
			left: 5%;
			z-index: 999;
			width: 40px;
			height: 40px;
			text-align: center;
			@media screen and ( min-width : 684px ) and ( max-width : 1024px ){
				left: 17%;
			}
			@media screen and ( min-width : 1024px ){
				left: 28%;
			}
			&::before{
			  position: absolute;
			  bottom: 0;
				left:5px;
			  content: '\f053';
			  color: #fff;
			  font-family: fontAwesome;
				opacity: 1;
			}
		}
	}
	#contents{
		padding-top: 20px;
	}
  #main{
    #menu{
			#menu-products{
				background: url('/_uploads/menu-products.jpg') no-repeat center / cover;
			}
			#menu-stores{
				background: url('/_uploads/menu-stores.jpg') no-repeat center / cover;
			}
			#menu-faq{
				background: url('/_uploads/menu-faq.jpg') no-repeat center / cover;
			}
			#menu-eventcar{
				background: url('/_uploads/menu-eventcar.png') no-repeat center / cover;
			}
			#menu-plant{
				background: url('/_uploads/menu-plant.jpg') no-repeat center / cover;
			}
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
      .menu{
        margin-bottom: 15px;
        font-size: 1.1rem;
				font-weight: bold;
        a{
	        border:1px solid #eee;
          padding: 2.2rem 10px;
          display: block;
          text-decoration: none;
          transition: .2s;
          border:1px solid #fff;
					color: $color-base;
          &:hover{
          }
        }
      }
			@include mq('min', 'lg') {
				.menu{
					width: 24%;
					a{
						padding: 3rem 10px;
					}
				}
			}
			@include mq('max', 'lg') {
				.menu{
					width:49%;
					@include mq('max', 'sm') {
						font-size: .9rem;
					}
				}
			}
    }
    .section{
      p{
        margin-bottom: 0;
      }
    }
		#news{
			h2{
				border-top:1px solid #ccc;
				padding-top: 2rem;
				margin-top: 1rem;
				a{
					text-decoration: none;
				}
			}
			ul{
				li{
					a{
						text-decoration: none;
						display: inline-block;
						padding: 8px 0;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
		#event{
			h2{
				border-bottom:1px solid #ccc;
				padding-bottom: 1em;
			}
		}
		#instagram{
			h2{
				border-top:1px solid #ccc;
				padding-top: 2rem;
				margin-top: 2rem;
			}
		}
		#risuya{
			a{
				padding: 10px 10px 10px 15px;
				border: 1px solid #ddd;
				display: inline-block;
			}
		}
  }
}
.products{
	#main{
		.flag{
			border-radius: 5px;
			padding:1px 4px;
			display: inline-block;
			font-size: .9rem;
	  }
	  .flag-new, .flag-red{
	    background:$color-red;
	    color:#fff;
	  }
	  .flag-limited, .flag-green{
	    background:$color-green;
	    color:#fff;
	  }
	  .flag-popular, .flag-orange{
	    background:$color-orange;
	    color:#fff;
	  }
	}
}
.products-top{
	.tab-content{
    display: flex;
    flex-wrap: wrap;
  }
  #main{
		.col-6{
	    padding:0 !important;
			.box{
		    margin:10px;
				a{
					text-decoration: none;
				}
		  }
			.caption{
				font-size: .8rem;
				padding-top:.2rem;
				text-align: center;
			}
	  }
	  .index-image{
	    background: none !important;
      width: 100%;
      border-radius: 10px;
	  }
	  .index-image img{
			width: 100%;
		}
	  .index-image span{
			border: 5px solid $color-base20;
	    width: 100%;
	    overflow: hidden;
	    border-radius: 10px;
	    display: block;
	    background-size:100% !important;
	    background-position: center center !important;
			& +span{
				display: none;
			}
	  }
		.s-popular,.s-orange{
			.index-image span{
				border-color:$color-orange;
			}
		}
		.s-limited,.s-green{
			.index-image span{
				border-color:$color-green;
			}
		}
		.s-new,.s-red{
			.index-image span{
				border-color:$color-red;
			}
		}
		@media screen and ( max-width : 768px ){
	  }
		.tab-content{
			.s-new,.s-red{
				.index-image span{
			    animation: newItems 6s ease infinite;
			  }
			}
		}
	  @keyframes newItems{
	    0%{background-size:100%;border-radius: 10%;}
	    10%{background-size:100%;border-radius: 50%;}
	    15%{background-size:100%;border-radius: 50%;}
	    20%{background-size:160%;border-radius: 50%;}
	    80%{background-size:160%;border-radius: 50%;}
	    85%{background-size:100%;border-radius: 50%;}
	    90%{background-size:100%;border-radius: 50%;}
	    100%{background-size:100%;border-radius: 10%;}
	  }
		.tab-content{
			.s-popular,.s-orange{
				.index-image span{
			    animation: popularItems 6s ease infinite;;
			  }
			}
		}
	  @keyframes popularItems{
	    0%{border-radius: 10%;transform: rotate(0deg)}
	    5%{border-radius: 50%;transform: rotate(0deg)}
	    10%{border-radius: 50%;transform: rotate(10deg)}
	    15%{border-radius: 50%;transform: rotate(-5deg)}
	    20%{border-radius: 50%;transform: rotate(5deg)}
	    25%{border-radius: 50%;transform: rotate(-5deg)}
	    30%{border-radius: 50%;transform: rotate(5deg)}
	    35%{border-radius: 50%;transform: rotate(-5deg)}
	    40%{border-radius: 50%;transform: rotate(5deg)}
	    45%{border-radius: 50%;transform: rotate(0deg)}
	    95%{border-radius: 50%;transform: rotate(0deg)}
	    100%{border-radius: 10%;transform: rotate(0deg)}
	  }
		.box{
	    position: relative;
	  }
		.flag{
			position: absolute;
			top:-2px;
			left:-2px;
			font-size: 12px;
			z-index: 100;
		}
	}

}
.products-detail{
	#main{
		#headline{
			h1{
				line-height: 1.2;
			}
		}
		.slider{
			margin-bottom: 8px;
		}
		.thumbnail-list{
			text-align: center;
			.thumbnail-item{
				border:1px solid #fff;
				&.slick-current{
					border-color: $color-base;
				}
			}
			img.thumbnail-item{
				border-top:1px solid #fff;
				cursor: pointer;
			}
		}
		.text{
			dt{
				margin-bottom: 1.8rem;
				margin-top: 3rem;
				border-bottom: 1px solid $color-base;
				span{
					position: absolute;
					margin: -1rem auto 0;
					background: #fff;
					padding: 0;
					height: 2rem;
					width:5rem;
					left: 0;
					right:0;
					text-align: center;
				}
			}
			dd + dt, dt.allergie{
				span{
					width:9rem;
				}
			}

			dd.allergie{
				// display: flex;
				// flex-wrap: wrap;
				// justify-content: space-between;
				table{
					margin-bottom: 10px;
					// border-left:1px solid #694f42;
					width: 100%;
					tbody{
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
					}
					&+table{
						@include mq('max', 'sm') {
							// margin-left: 5px;
						}
					}
					tr{
						display: block;
				    // float: left;
						// border: solid #694f42;
						// border-width: 0 0 1px;
						&.flex-1{
							flex:1;
						}
						&.flex-2{
							flex:2;
						}
						&.flex-3{
							flex:3;
						}
						&.flex-4{
							flex:4;
						}
						&.flex-5{
							flex:5;
						}
						&.flex-6{
							flex:6;
						}
						&.flex-7{
							flex:7;
						}
						th, td{
							display: block;
							border: solid #694f42;
							border-width: 1px 1px 1px 0;
							padding: 3px 5px;
							text-align: center;
							font-size: .85rem;
							height: 1.6rem;
							vertical-align: middle;
							min-width: 1.8rem;
							@include mq('max', 'sm') {
								font-size: .7rem;
							}
						}
						th{
							background: #f0edec;
						}
						td{
							min-width: 1.3rem;
							border-top-color: #fff;
						}
						&:first-child{
							th, td{
								border-left: 1px solid #694f42;
							}
						}
					}
				}
				strong{
					span{
						padding: .25em .6em;
						font-size: 1rem;
						background: #eee;
					}
				}
			}
			dd.nutrition{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				p{
					font-size: .8rem;
					margin-bottom: .4rem;
				}
				table{
					caption{
						caption-side: top;
						text-align: center;
						padding: 0 0 .4rem;
						color: $color-base
					}
					td{
						text-align: right;
						padding-left: 1.4rem;
					}
				}
			}
		}
		#free, #recipe{
			background: url('/_uploads/products-recipe-bg.png');
			text-align: center;
			h1, h2{
				font-size: 1.5rem;
				margin-bottom: 1.8rem;
			}
			img{
				max-width: 100%;
				height: auto;
			}
			img+img{
				margin-top: 2rem;
			}
		}
		#free{
		}
	}
}
.event{
	#event-cal{
		table{
			caption{
				caption-side: top;
				text-align: center;
				height: 45px;
				color: $color-base;
				font-weight: bold;
			}
			th, td{
				text-align: center;
				border: 0;
				padding:5px;

			}
			th{
				color:$color-base;
			}
			td{
				color: rgba(104,79,67,.25);
				&.today{
					// opacity: 1;
					background: rgba(244, 156, 85, .4);
				}
				&.on{
					color: $color-base;
					a{
						display: block;
					}
					&:hover{
						background: rgba(244, 156, 85, .4);
					}
				}
				a{
					text-decoration: none;
				}
			}
			thead{
				position: relative;
				a{
					position: fixed;
					display: block;
					&.cal-prev{
						left: 0;
					}
					&.cal-next{
						right: 0;
					}
				}
			}
		}
		&> a{
			position: absolute;
			display: block;
			font-size: .8rem;
			padding-top: 15px;
			text-decoration: none;
			&.cal-prev{
				left: 15px;
			}
			&.cal-next{
				right: 15px;
			}
		}
	}
	#contents{
		.widget-content{
			position: relative;

		}
		.cal-nav{
			position: absolute;
			width: 100%;
			z-index: 1;
			display: flex;
			justify-content:space-between;
			a{
				display: block;
				font-size: .8rem;
				padding-top: 15px;
				text-decoration: none;
			}
		}
	}
}
.event-list{
	#contents{
		.widget-content{
			position: relative;

		}
		.cal-nav{
			position: absolute;
			width: 100%;
			z-index: 1;
			display: flex;
			justify-content:space-between;
			a{
				display: block;
				font-size: .8rem;
				padding-top: 15px;
				text-decoration: none;
			}
		}
		.content-nav{
			margin: 3.5rem 0 40px;
			display: flex;
			justify-content: space-between;
			div{
				width: 30%;
				display: inline-block;
				&.btn-prev{
					order: 1;
				}
				&.btn-next{
					order: 3;
				}
				&.btn-top{
					order: 2;
				}
				a{
					background: $color-base;
					border:1px solid $color-base;
					color: #fff;
					text-decoration: none;
					padding: 5px;
					border-radius: 5px;
					text-align: center;
					display: block;
					&:hover{
						border:1px solid $color-base;
						background: #fff;
						color: $color-base;
					}
				}
			}
		}
		.item-info{
			h2{
				font-size: 1.1rem;
			}
		}
	}
}

.event-calendar{
	#contents{
		.cal-nav{
			position: absolute;
			width: 100%;
			z-index: 1;
			display: flex;
			justify-content:space-between;
			span{
				display: block;
				font-size: .8rem;
				padding-top: 15px;
				text-decoration: none;
				cursor: pointer;
			}
		}
	}
}
.event-detail{
	#contents{
		h2{
			font-size: 1.4rem;
			// font-size: 1rem;
			// background: $color-base;
			// color: #fff;
		}
		h3{
			font-size: 1.8rem;
		}
		.gmap {
			position: relative;
			width: 100%;
			padding-top: 75%;
			iframe {
				position: absolute;
				top: 0;
				right: 0;
				width: 100% !important;
				height: 100% !important;
			}
		}
		hr{
			margin: 3rem 0 2rem;
		}
		.content-nav{
			margin: 3.5rem 0 40px;
			display: flex;
			justify-content: space-between;
			div{
				width: 30%;
				display: inline-block;
				&.btn-prev{
					order: 1;
				}
				&.btn-next{
					order: 3;
				}
				&.btn-top{
					order: 2;
				}
				a{
					background: $color-base;
					border:1px solid $color-base;
					color: #fff;
					text-decoration: none;
					padding: 5px;
					border-radius: 5px;
					text-align: center;
					display: block;
					&:hover{
						border:1px solid $color-base;
						background: #fff;
						color: $color-base;
					}
				}
			}
		}
	}
}

.news{
	#contents{
		#content-nav{
			border-top: 4px solid $color-base;
			padding-top: 3rem;
		}
	}
}
.news-list{
	#contents{
		.asset{
			border-top: 4px solid $color-base;
			padding: 20px 0 25px;
		}
		.asset-name a{
			text-decoration: none;
		}
		.asset-meta{
			font-size: .8rem;
			margin-top:1rem;
			text-align: right;
		}
	}
}
.news-detail{
	#contents{
		.entry-meta{
			font-size: .8rem;
		}
		#local-nav{
			a{
				background: #eeedec;
				padding: 5px 10px;
				display: inline-block;
				text-decoration: none;
			}
		}
	}
}

.page{
	#contents{
		h2{
			font-size: 2.2rem;
		}
	}
}
.recruit{
	#contents{
		p.name img, #voice img{
			border-radius: 50%;
		}
		#message{
			h3{
				color: $color-orange;
				font-size: 1.3rem;
			}
		}
		#voice{
			border-top:3px solid $color-base;
			border-bottom:3px solid $color-base;
			h3{
				font-size: 1rem;
				margin-top: .5rem;
			}
			a{
				text-decoration: none;
			}
			p{
				line-height: 1.5;
				height: 90px;
				img{
					width: 90px;
					float: left;
					margin-right:.8rem;
					height: 90px;
				}
				strong{
					padding-top: 1.2rem;
					display: block;
				}
			}
		}
		table{
			table{
				margin-bottom: 0;
			}
			th{
				background: $color-base20;
				border: 1px solid $color-base20;
			}
			td{
				border: 1px solid $color-base20;
			}
			@include mq('max', 'md') {
				tr,th,td{
					display: block;
				}
			}
		}
		#outline{
			.copy p{
				color: $color-orange;
				font-weight: bold;
				font-size: 1.3rem;
			}
		}
	}
}
.recruit-message{
	#contents{
		.profile{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 2rem;
			p{
				img{
					border-radius: 50%;
					margin-right: 1rem;
				}
				strong{
					display: block;
					font-size: 1.3rem;
				}
			}
		}
		.voice{
			h3{
				margin-bottom: 2rem;
			}
			dt{
				margin-bottom: .5rem;
			}
			dd{
				margin-bottom: 1rem;
				img{
					margin-top: .5rem;
				}
			}
		}
	}
}
.corporate_info{
	#contents{
		#company-philosophy{
			li{
				font-size: 1rem;
			}
		}
		#outline{
			table{
				th,td{
					padding:.2rem 0;
					vertical-align: text-top;
				}
				th{
					padding-right: 2rem;
				}
			}
		}
		#history{
			table{
				width: 100%;
				th,td{
					padding:.2rem 0;
					vertical-align: text-top;
				}
				th{
					padding-right: 2rem;
					width: 12rem;
				}
			}
		}
	}
}
.stores{
	#contents{
		h2{
			margin-bottom: 1rem;
		}
		#store-map{
			#gmap {
		    position: relative;
		    width: 100%;
		    padding: 52.17% 0 0 0;
				iframe {
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    border:0;
				}
			}
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			padding-left: 0;
			margin-bottom: 1.5rem;
			li{
				width: 30%;
				margin-left:1.4rem;
				font-size: 1rem;
			}
		}
		#fukui, #shiga{
			border-top: 1px solid $color-base50;
		}
		p{
			font-size: .8rem;
		}
	}
}
.factory_tour{
	#contents{
		a.btn{
			background: $color-base;
			border:1px solid $color-base;
			color: #fff;
			text-decoration: none;
			padding: .5rem 1rem;
			border-radius: 5px;
			display: inline-block;
			&:hover{
				background: #fff;
				color: $color-base;
			}
		}
	}
}
.risuya{
	#about{
		h1{
			@include mq('max', 'sm') {
				img{
					max-width:200px !important;
				}
			}
		}
	}
	.gmap {
		position: relative;
		width: 100%;
		padding-top: 75%;
  }
	.gmap iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
  }
}
